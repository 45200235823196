<template>
    <div id="app">
        <MazInput
            v-model="imei"
            :placeholder="placeholder"
            :success="valid"
            :error="invalid"
            @keyup="validateImei"
        />
        <button
            class="select"
            :disabled="!imei"
            :style="{ background: theme }"
            @click="select"
        >
            OK
        </button>
    </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5'
import { MazInput } from 'maz-ui'
import LocalizedStrings from 'localized-strings'

var luhn = {
    /* Calculates the Luhn checksum */
    calculate: function (digits) {
        var sum = this.sum(digits, false)
        return (sum * 9) % 10
    },

    /* Verifies if a number is a valid Luhn checksum */
    verify: function (digits) {
        var sum = this.sum(digits, true)
        return sum > 0 && sum % 10 === 0
    },

    /* Sum each digit from right to left, and double
         every second digit. If the double exceeds 9,
         then sum its digits (i.e., 654321 -> 358341
         -> 24) */
    sum: function (digits, even) {
        var sum = 0
        var digit = 0
        var i = digits.length

        while (i--) {
            digit = Number(digits[i])
            if (even = !even) {
                sum += this.computed[digit]
            } else {
                sum += digit
            }
        }

        return sum
    },

    /* Create a precomputed list based on doubling
         each digit, as described in sum(). */
    computed: [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
}

let strings = new LocalizedStrings(
    {
        en: {
            placeholder: 'IMEI (dial *#06#)'
        },
        fr: {
            placeholder: 'IMEI (composer *#06#)'
        },
        de: {
            placeholder: 'IMEI (wählen *#06#)'
        }
    },
    {
        customLanguageInterface: () => {
            return new Intl.DateTimeFormat().resolvedOptions().locale
        }
    }
)

export default {
    components: { MazInput },
    data () {
        return {
            theme: '#333333',
            momentsSDK: null,
            imei: '',
            valid: null,
            invalid: null,
            placeholder: strings.placeholder,
            defaultCountryCode: new Intl.DateTimeFormat()
                .resolvedOptions()
                .locale.slice(-2)
                .toUpperCase()
        }
    },
    methods: {
        validateImei () {
            // reset
            this.valid = null
            this.invalid = null
            if (!this.imei) {
                return
            }

            // run the check
            let isValid = luhn.verify(this.imei)

            if (isValid) {
                this.valid = true
                this.invalid = false
            } else {
                this.valid = false
                this.invalid = true
            }
        },
        select () {
            if (!this.imei || !this.momentsSDK) {
                return
            }

            this.momentsSDK.sendMessage({ text: this.imei })
            this.momentsSDK.close()
        }
    },
    async created () {
        createMomentsSDK({
            title: 'IMEI',
            icon: `${window.location.origin}${window.location.pathname}/favicon.png`
        }).then((momentsSDK) => {
            this.momentsSDK = momentsSDK
        })
    }
}
</script>

<style lang="sass">
*
        margin: 0
        padding: 0
        box-sizing: border-box
        outline: none

        html, body, #app
                height: 100%
                width: 100%

        #app
                padding: 10px

        button.select
                width: 100%
                margin-top: 15px
                padding: 10px
                color: #ffffff
                border: none
                border-radius: 4px
                font-size: 16px
                -webkit-backface-visibility: hidden
                transition: all .3s
                cursor: pointer
                &:hover
                        opacity: 0.8
                &:active
                        opacity: 0.5
                &:disabled
                        opacity: 0.2

        @import './src/assets/styles/main.scss'
</style>
