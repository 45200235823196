import Vue from 'vue'
import App from './App.vue'
import 'maz-ui/lib/css/index.css'
import { MazInput } from 'maz-ui'

Vue.use(MazInput)

Vue.config.productionTip = false

new Vue({
    render: h => h(App)
}).$mount('#app')
